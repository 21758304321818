import { StarIcon } from '@heroicons/react/20/solid';

function TestimonialsSlider() {
  return (
    <section className="text-primary-950 lg:container-default section-default">
      <div className="bg-ui-100 py-16 px-5 lg:rounded-default">
        <div className="flex items-center gap-1 justify-center">
          <StarIcon className="fill-utility-yellow w-4" />
          <StarIcon className="fill-utility-yellow w-4" />
          <StarIcon className="fill-utility-yellow w-4" />
          <StarIcon className="fill-utility-yellow w-4" />
          <StarIcon className="fill-utility-yellow w-4" />
        </div>
        <blockquote className="text-center text-xl mt-8 max-w-2xl mx-auto italic">
          “This tool is fantastic and the support is amazing. I wish I learned
          about it way sooner. I can easily pull data from our business and show
          our customers how they can get their data with the tool and they
          don&apos;t even need developers. Love this!”
        </blockquote>
        <div className="flex justify-center items-center gap-2 w-full mt-4">
          <img
            alt="Andy Nadler"
            className="w-6"
            src="/images/home/testimonials/andy-nadler.png"
          />
          <div className="">Andy Nadler | Accenture</div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialsSlider;
